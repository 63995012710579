
import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
// Services
import { useBrand, useModel, useNomenclature, useSegment } from "@/services";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Interfaces
import {
  IBrandRead,
  IResponseListBrands,
} from "@core/services/interfaces/vehicle/brands/IBrandService";
import {
  IModel,
  IResponseListModels,
} from "@core/services/interfaces/vehicle/models/IModelService";
import {
  IBodyList,
  INomenclature,
  IResponseGetCategory,
  IResponseListNomenclature,
} from "@core/services/interfaces/vehicle/nomenclature/INomenclatureService";
import {
  IResponseListSegment,
  ISegment,
} from "@core/services/interfaces/vehicle/segment/ISegmentService";
import useTable, { IFilterOptions } from "@core/utils/useTable";
import { Ref, ref } from "@vue/composition-api";
import {
  BCard,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BvTableFieldArray,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import NomenclatureEdit from "./nomenclature-edit-handler/NomenclatureEdit.vue";
import NomenclatureFilter from "./nomenclature-edit-handler/NomenclatureFilter.vue";

interface IExportNomenclature {
  Código: number;
  Marca: string;
  Modelo: string;
  "Versão Veículo": string;
  "Descrição Original": string;
  Cilindradas: string;
  Categoria: string;
  "Segmento Mercado": string;
  "Segmento Associado": string;
  "Registro Novo": string;
  Ativo: string;
}

@Component({
  name: "NomenclaturesTab",
  components: {
    AvSkeletonTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
    AvButton,
    AvExportButton,
    BInputGroup,
    BInputGroupAppend,
    NomenclatureFilter,
    NomenclatureEdit,
  },
  directives: {
    Ripple,
  },
})
export default class NomenclaturesTab extends Vue {
  models: Array<IModel> = [];
  modelFilter: number | null = null;
  modeloId: string = "";
  paginar: boolean = false;
  segments: Array<ISegment> = [];
  marketSegmentFilter: number | null = null;
  associatedSegmentFilter: number | null = null;
  nomenclatures: Array<INomenclature> = [];
  isSideBarActive: Ref<boolean> = ref(false);
  searchedVersionName: string = "";
  isEditing: boolean = false;
  refGroupsListTable: number | null = null;
  fabricanteOptions: Array<{ label: string; value: number }> = [];
  segmentMercadoOptions: Array<{ label: string; value: number }> = [];
  segmentAssociadoOptions: Array<{ label: string; value: number }> = [];
  modelOptions: Array<{ label: string; value: number; key: number }> = [];
  categoryOptions: Array<{ label: string; value: number }> = [];
  codigoFilter: string = "";
  cilindradasFilter: string = "";
  descricaoOriginalFilter: string = "";
  ativoFilter: null | boolean = null;
  registroNovoFilter: null | boolean = null;
  marcaFilter: null | number = null;
  categoriaFilter: null | number = null;
  versaoVeiculoFilter: null | string = null;

  tableColumns: BvTableFieldArray = [
    { key: "Codigo", label: "Código", sortable: true },
    { key: "Fabricante", label: "Marca", sortable: true },
    { key: "Modelo", label: "Modelo", sortable: true },
    { key: "Nome", label: "Versão Veículo", sortable: true },
    { key: "DescricaoOriginal", label: "Descrição Original", sortable: true },
    { key: "Cilindradas", label: "Cilindradas", sortable: true },
    { key: "CategoriaVeiculo", label: "Categoria", sortable: true },
    {
      key: "Segmento",
      label: "Segmento Mercado",
      sortable: true,
    },
    {
      key: "SegmentoAssociado",
      label: "Segmento Associado",
      sortable: true,
    },
    { key: "RegistroNovo", label: "Registro Novo", sortable: true },
    { key: "Ativo", label: "Ativo", sortable: true },
    { key: "actions", label: "Ações", tdClass: "text-center" },
  ];

  confirmationOptions = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  statusOptions = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];

  useTable = new useTable(this.tableColumns, {
    sortBy: "GrupoVeiculoNome",
    customFilter: this.customFilter,
  });

  get dataMeta() {
    const localItemsCount = this.refGroupsListTable
      ? this.refGroupsListTable
      : 0;

    return {
      from:
        this.useTable.perPage.value * (this.useTable.currentPage.value - 1) +
        (localItemsCount ? 1 : 0),
      to: this.useTable.perPage.value * this.useTable.currentPage.value,
      of: this.useTable.display.value,
      total: this.useTable.total.value,
    };
  }

  created() {
    this.fetchListNomenclatures();
    this.fetchListFabricantes();
    this.fetchListModels();
    this.fetchListCategories();
    this.fetchListSegments();
  }

  async fetchListNomenclatures() {
    const amountPerRequest = 5000;
    let requests = [];
    let total = 0;
    let success = true;
    let nomenclatureData: INomenclature[] = [];

    await useNomenclature
      .requestList({
        paginate: true,
        draw: 1,
        length: amountPerRequest,
      })
      .then((response: IResponseListNomenclature) => {
        total = response.data.recordsTotal;
        nomenclatureData = response.data.data;
      })
      .catch(() => {
        success = false;
      });

    const pages = Math.ceil(total / amountPerRequest);
    for (let page = 2; page <= pages; page++) {
      requests.push(
        useNomenclature.requestList({
          paginate: true,
          draw: page,
          length: amountPerRequest,
        })
      );
    }

    await Promise.all(requests)
      .then((response: IResponseListNomenclature[]) => {
        this.useTable.listTable.value = response.reduce(
          (
            prevValue: INomenclature[],
            currentValue: IResponseListNomenclature
          ) => {
            return prevValue.concat(currentValue.data.data);
          },
          nomenclatureData
        );
      })
      .catch(() => {
        success = false;
      });

    this.useTable.filterItems();
    this.useTable.firstRequest.value = false;
    if (!success) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Erro ao buscar nomenclatura!",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  }

  customFilter(item: INomenclature): boolean {
    let filtered = {
      modelFilter: true,
      marcaFilter: true,
      marketSegmentFilter: true,
      associatedSegmentFilter: true,
      categoriaFilter: true,
      descricaoOriginalFilter: true,
      ativoFilter: true,
      registroNovoFilter: true,
      cilindradasFilter: true,
      codigoFilter: true,
    };

    if (this.modelFilter) {
      filtered.modelFilter = item.GrupoVeiculoId == this.modelFilter;
    }

    if (this.marcaFilter) {
      filtered.marcaFilter = item.FabricanteId == this.marcaFilter;
    }

    if (this.marketSegmentFilter) {
      filtered.marketSegmentFilter =
        item.SegmentoId == this.marketSegmentFilter;
    }

    if (this.associatedSegmentFilter) {
      filtered.associatedSegmentFilter =
        item.SegmentoAssociadoId == this.associatedSegmentFilter;
    }

    if (this.categoriaFilter) {
      filtered.categoriaFilter =
        item.CategoriaVeiculoId == this.categoriaFilter;
    }

    if (this.descricaoOriginalFilter != "") {
      const reg = new RegExp(this.descricaoOriginalFilter, "g");
      filtered.descricaoOriginalFilter = item.DescricaoOriginal.match(reg)
        ? true
        : false;
    }

    if (typeof this.ativoFilter == "boolean") {
      filtered.ativoFilter = item.Ativo == this.ativoFilter;
    }

    if (typeof this.registroNovoFilter == "boolean") {
      filtered.registroNovoFilter =
        item.RegistroNovo == this.registroNovoFilter;
    }

    if (this.cilindradasFilter != "") {
      const reg = new RegExp(this.cilindradasFilter, "g");
      filtered.cilindradasFilter = item.Cilindradas.match(reg) ? true : false;
    }

    if (this.codigoFilter != "") {
      const reg = new RegExp(this.codigoFilter, "g");
      filtered.codigoFilter = `${item.Codigo}`.match(reg) ? true : false;
    }

    // Checando se todos os filtros foram atendidos
    return Object.entries(filtered).filter((item) => !item[1]).length == 0;
  }

  @Watch("modelFilter")
  @Watch("marcaFilter")
  @Watch("marketSegmentFilter")
  @Watch("associatedSegmentFilter")
  @Watch("categoriaFilter")
  @Watch("descricaoOriginalFilter")
  @Watch("ativoFilter")
  @Watch("registroNovoFilter")
  @Watch("cilindradasFilter")
  @Watch("codigoFilter")
  @Watch("useTable.searchQuery.value")
  refetchData() {
    // this.saveFilter();
    this.useTable.filterItems();
    this.$toast({
      component: ToastificationContent,
      props: {
        title: "Consulta realizada",
        text: "Filtro com sucesso!",
        icon: "CheckIcon",
        variant: "success",
      },
    });
  }

  searchVersion() {
    this.useTable.searchQuery.value = this.searchedVersionName;
  }

  updateIsSidebarActive(val: boolean) {
    this.modeloId = "";
    this.isSideBarActive.value = val;
  }

  fetchListSegments() {
    useSegment
      .requestList()
      .then((response: IResponseListSegment) => {
        this.segments = response.data.Data;
        this.fetchListSegmentsMercado();
        this.fetchListSegmentsAssociados();
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao obter segmentos!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  fetchListSegmentsMercado() {
    const segmentosMercado = this.segments.filter((option) => {
      return option.Tipo === "Mercado";
    });
    this.segmentMercadoOptions = segmentosMercado.map((option) => {
      return {
        label: option.Nome,
        value: option.Id,
      };
    });
  }

  fetchListSegmentsAssociados() {
    const segmentosAssociados = this.segments.filter((option) => {
      return option.Tipo === "Associacao";
    });
    this.segmentAssociadoOptions = segmentosAssociados.map((option) => {
      return {
        label: option.Nome,
        value: option.Id,
      };
    });
  }

  fetchListModels() {
    useModel
      .requestList({ paginate: this.paginar, userdata: {} })
      .then((response: IResponseListModels) => {
        let models = response.data.data;
        this.modelOptions = models.map((option) => {
          return {
            label: option.Nome,
            value: option.Id,
            key: option.Id,
          };
        });
      })
      .catch((response) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Um erro ocorreu ao buscar a lista de Modelos!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return response;
      });
  }

  fetchListFabricantes() {
    useBrand
      .requestList({ draw: 1 })
      .then((response: IResponseListBrands) => {
        let fabricantes = response.data.data;
        this.fabricanteOptions = fabricantes.map(
          (option: IBrandRead): { label: string; value: number } => {
            return {
              label: option.Nome,
              value: option.Id ? option.Id : -1,
            };
          }
        );
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao recuperar marcas!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }
  fetchListCategories() {
    useNomenclature
      .requestGet()
      .then((response: IResponseGetCategory) => {
        const categoria = response.data.Data;
        this.categoryOptions = categoria.map(
          (option): { label: string; value: number } => {
            return {
              label: option.Nome,
              value: option.Id,
            };
          }
        );
      })
      .catch((response) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar Categorias!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return response;
      });
  }

  clearAllFilters() {
    this.modelFilter = null;
    this.marcaFilter = null;
    this.marketSegmentFilter = null;
    this.associatedSegmentFilter = null;
    this.categoriaFilter = null;
    this.descricaoOriginalFilter = "";
    this.ativoFilter = null;
    this.registroNovoFilter = null;
    this.cilindradasFilter = "";
    this.codigoFilter = "";
    this.searchedVersionName = "";
    this.useTable.searchQuery.value = "";
  }

  handleUpdate(modeloId: string) {
    this.modeloId = modeloId;
    this.isSideBarActive.value = true;
  }

  mapFilterToBody(options: IFilterOptions): IBodyList {
    let body = this.useTable.mapFilterToBody(options);
    body.userdata = {};

    if (this.modelFilter) {
      body.userdata.GrupoVeiculoId = this.modelFilter;
    }

    if (this.marcaFilter) {
      body.userdata.fabricanteId = this.marcaFilter;
    }

    if (this.marketSegmentFilter) {
      body.userdata.segmentoId = this.marketSegmentFilter;
    }

    if (this.associatedSegmentFilter) {
      body.userdata.segmentoAssociadoId = this.associatedSegmentFilter;
    }
    if (this.categoriaFilter) {
      body.userdata.categoriaVeiculoId = this.categoriaFilter;
    }

    if (this.descricaoOriginalFilter != "") {
      body.userdata.descricaoOriginal = this.descricaoOriginalFilter;
    }

    if (typeof this.ativoFilter == "boolean") {
      body.userdata.ativo = this.ativoFilter;
    }

    if (typeof this.registroNovoFilter == "boolean") {
      body.userdata.registroNovo = this.registroNovoFilter;
    }

    if (this.cilindradasFilter != "") {
      body.userdata.cilindradas = this.cilindradasFilter;
    }

    if (this.codigoFilter) body.userdata.codigo = this.codigoFilter;

    return body;
  }

  getNomenclatures() {
    return new Promise(async (resolve, reject) => {
      let data: any = [];
      let requests: Array<Promise<IResponseListNomenclature>> = [];

      let amountPerRequest = 10000;
      let pages = Math.ceil(this.useTable.display.value / amountPerRequest);

      const body = this.mapFilterToBody({
        perPage: this.useTable.perPage.value,
        currentPage: this.useTable.currentPage.value,
        searchQuery: this.useTable.searchQuery.value,
        sortyBy: this.useTable.sortBy.value,
        isSortDirDesc: this.useTable.isSortDirDesc.value,
        filters: this.useTable.filters,
      });

      for (let page = 1; page <= pages; page++) {
        requests.push(
          useNomenclature.requestList({
            ...body,
            paginate: true,
            draw: page,
            length: amountPerRequest,
          })
        );
      }

      await Promise.all(requests)
        .then((response: IResponseListNomenclature[]) => {
          // Unir os arrays de resultados em um único array
          data = response.reduce(
            (
              prevValue: IExportNomenclature[],
              currentValue: IResponseListNomenclature
            ) => {
              const nomenclatures: IExportNomenclature[] =
                currentValue.data.data.map((nomenclature) => ({
                  Código: nomenclature.Codigo,
                  Marca: nomenclature.Fabricante,
                  Modelo: nomenclature.Modelo,
                  "Versão Veículo": nomenclature.Nome,
                  "Descrição Original": nomenclature.DescricaoOriginal,
                  Cilindradas: nomenclature.Cilindradas,
                  Categoria: nomenclature.CategoriaVeiculo,
                  "Segmento Mercado": nomenclature.Segmento,
                  "Segmento Associado": nomenclature.SegmentoAssociado,
                  "Registro Novo": nomenclature.RegistroNovo ? "Sim" : "Não",
                  Ativo: nomenclature.Ativo ? "Ativo" : "Inativo",
                }));

              return prevValue.concat(nomenclatures);
            },
            []
          );

          return response;
        })
        .catch((erro) => {
          reject(erro);
        });

      resolve(data);
    });
  }
}
